<i18n>
ru:
  amountLeftForGift: 'До подарка ещё {toSum}'
  amountToNextGift: 'Ещё {toSum} до следующего подарка'
  choose: Выбрать
  edit: Изменить
  giftAvailable: Доступен подарок к заказу
  giftWithOrder: Подарок к заказу
ua:
  amountLeftForGift: 'Залишилося {toSum} до подарунка'
  amountToNextGift: 'Ще {toSum} до наступного подарунка'
  choose: Вибрати
  edit: Редагувати
  giftAvailable: Доступний подарунок до замовлення
  giftWithOrder: Подарунок до замовлення
us:
  amountLeftForGift: '{toSum} left for the gift'
  amountToNextGift: '{toSum} to the next gift'
  choose: Choose
  edit: Edit
  giftAvailable: Gift available with order
  giftWithOrder: Gift with order
</i18n>

<template>
  <div
    class="v-gifts-ranged v-d-flex v-pointer v-align-items-center"
    @click="callForPopup"
  >
    <div class="v-gifts-ranged__icon">
      <icon-manual-ranged-present-open v-if="cartToPayWithMoney > milestones[0]" />
      <icon-manual-ranged-present v-else />
    </div>
    <div class="v-gifts-ranged__text">
      <span
        class="v-typography-title-5"
        v-html="translate('giftsRanged.giftWithOrder')"
      />
      <template v-if="milestones.length > currentIndex">
        <i18n-t
          v-if="cartToPayWithMoney > milestones[0]"
          class="v-d-flex v-typography-text-5 v-opacity-75"
          keypath="giftsRanged.amountToNextGift"
          scope="global"
          tag="div"
        >
          <template #toSum>
            <common-currency
              class="v-mr-xxs v-ml-xxs"
              :amount="milestones[currentIndex] - cartToPayWithMoney"
            />
          </template>
        </i18n-t>
        <i18n-t
          v-else
          class="v-d-flex v-typography-text-5 v-opacity-75"
          keypath="giftsRanged.amountLeftForGift"
          scope="global"
          tag="div"
        >
          <template #toSum>
            <common-currency
              class="v-mr-xxs v-ml-xxs"
              :amount="milestones[0] - cartToPayWithMoney"
            />
          </template>
        </i18n-t>
      </template>
    </div>
    <div
      v-if="!appConfig.VueSettingsPreRun.GiftsRangedHideButton"
      class="v-gifts-ranged__button"
    >
      <span
        class="v-typography-title-6"
        v-html="translate(`giftsRanged.${hasRangedGiftInCart ? 'edit' : 'choose'}`)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GiftsRanged } from '~types/props'

import { PositionType } from '~api/consts'

defineProps<GiftsRanged>()

const clientStore = useClientStore()

const appConfig = useAppConfig()

const hasRangedGiftInCart = computed<boolean>(() =>
  (clientStore.ClientState.data?.Cart?.Content ?? []).length > 0
    ? clientStore.ClientState.data!.Cart!.Content.some(
        (item) => item.PositionType === PositionType.RangedGift
      )
    : false
)

const { translate } = useI18nSanitized()
</script>

<style lang="scss">
@use 'assets/mixins';
@use 'assets/variables';

.v-gifts-ranged {
  border-radius: variables.$BorderRadius;
  background: variables.$SecondaryBackgroundColor;
  color: variables.$SecondaryColor;
  padding: 0.855rem;
  gap: 0.855rem;

  @include mixins.trans;

  &:hover {
    background: variables.$SecondaryBackgroundColorHover;
  }

  &__icon {
    flex: 0 0 40px;
    height: 40px;
  }

  &__text {
    flex: 1;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__button {
    flex: 0 0 6.785rem;
    width: 6.785rem;
    border-radius: variables.$BorderRadiusButton;
    background: variables.$SecondaryColor;
    color: variables.$SecondaryBackgroundColor;
    padding: 0.57rem 0;
    display: flex;
    justify-content: center;
  }
}
</style>
